@import "../../styles/variables";

.topSection {
  height: fit-content;
  background-image: url(../../img/Oxull_image.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $breakpoint-desktop) {
    height: 774px;
  }
}

.filter {
  width: 100%;
  height: 100%;
  background: $bgColorGradient;
  position: absolute;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  align-items: center;
  gap: 8px;
  padding: 122px 44px 44px;
  
  @media screen and (min-width: $breakpoint-desktop) {
    justify-content: center;
    padding: 72px 44px 44px;
    gap: 36px;
  }
  * {
    margin: 0;
    max-width: unset;
    z-index: 5;
    position: relative;
  }
  div > img {
    display: none;
    @media screen and (min-width: $breakpoint-desktop) {
      display: block;
    }
  }
  h1 {
    font-size: 26px;
    color: $primaryColor;
    text-align: center;
    @media screen and (min-width: $breakpoint-desktop) {
      font-size: 56px;
    }
  }

  h2 {
    text-align: center;
  }

  p {
    font-size: 16px;

    @media screen and (min-width: $breakpoint-desktop) {
      font-size: 36px;
    }
  }
}
