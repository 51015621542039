@import "../../styles/variables";

.imageWrapper {
  display: flex;
  justify-content: center;
  max-width: 400px;
  align-items: center;
  .image {
    width: 100%;
  }
}
.footerWrapper {
  background: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $textAlt;
  font-weight: 600;
  padding: $generalPadding;

  .contactData {
    gap: 8px;
    display: flex;
    text-decoration: underline;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    
    @media screen and (min-width: $breakpoint-desktop) {
        gap: 24px;
        flex-direction: row;
      }
  }
}
