@import "../../styles/variables";

.ulList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  & li {
    position: relative;
    background: $primaryColor;
    padding: 24px;
    padding-left: 74px;
    font-size: 18px;
    font-weight: 800;
    color: $secondary;

    &::before {
      content: "";
      position: absolute;
      background-image: url(../../img/Oxull_exclamation_secondary.png);
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      width: 40px;
      height: 40px;
      top: 50%;
      left: 17px;
      transform: translateY(-50%);
    }
  }
}