@import "../../styles/variables";

.headerWrapper {
  background: $secondaryTrans;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 6fr 2fr 1fr;
  padding: $generalPadding;
  position: fixed;
  z-index: 10;
  border-bottom: 2px solid $bgColor;

  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: 3fr 5fr;
}

  .logoWrapper {
    display: flex;
    // justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }

  
}
