@import "../../../styles/variables";

.marketingBg {
  background-image: url(../../../img/Oxull_marketingBg.png);
}

.italic {
  font-style: italic;
  font-weight: 700;
}
