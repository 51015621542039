@import "../../styles/variables";

.menuWrapper {
  display: flex;
  justify-content: space-around;
  list-style: none;
  display: none;

  @media screen and (min-width: $breakpoint-desktop) {
    display: flex;
  }

  a {
    color: $primaryColor;
    padding: 12px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    transition: $transition;

    &:hover {
      color: $bgColor;
    }
  }
}



.burgerMenuWrapper {
  background: $secondaryTransGradient;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  margin: 0;
  list-style: none;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 0;
  color: $primaryColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  box-sizing: border-box;
  text-shadow: 0px 0px 0.5px $secondary;

  li {
    a {
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 500;
    }
  }
  &__open {
    transition: all 0.3s ease-in-out;
    height: 100vh;
    padding-top: 72px;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.button {
  background: transparent;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3;

  @media screen and (min-width: $breakpoint-desktop) {
    display: none;
  }
}
