@import '../../styles/variables';

.layout {
  display: flex;
  background: $bgColor;
  flex-direction: column;
  min-height: 100vh;
}

.outlet {
  flex: 1;
}
