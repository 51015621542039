$primaryColor: #d75e0d;
$primaryColorHover: #75380f;
$secondary: #b4d1e9;
$secondaryTrans: #343739bf;
$secondaryTransGradient: linear-gradient(180deg, $secondaryTrans 0%, #343739ed 30%, #343739 100%);;
$bgColor: $secondary;
$textAlt: #d5e7f6;
$bgColorGradient: linear-gradient(180deg, rgba(180,209,233,0.5228685224089635) 20%, rgba(180,209,233,1) 100%);
$generalPadding: 18px;
$transition: all 0.4s ease-in-out;
$breakpoint-tablet: 744px;
$breakpoint-desktop: 1440px;
