@import "../../styles/variables";

.itemsGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  justify-items: center;

@media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 1fr 1fr;
}

  > * {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // gap: 12px;
    width: 100%;
    align-items: center;
    border: 2px solid $primaryColor;
    border-radius: 8px;
    padding: 16px;

    h2 {
      padding: 0;
    }

    img {
      max-width: 100px;
    }
  }
}