@import "../../styles/variables";

.container {
  padding: 24px;
  // padding-top: 115px;
  padding-top: 0;

  h1 {
    font-weight: 800;
    text-align: center;
  }
  h2 {
    color: $primaryColor;
    text-align: center;
    font-weight: 800;
    padding: 24px;
  }
  
  main>h3 {
    color: $primaryColor;
    font-style: italic;
  }
}