@import "../../../styles/variables";

.sectionBg {
  background-image: url(../../../img/Oxull_contactUsBg.png);

//   height: 320px;
}

// .custonSection {
//   div:has(*) {
//     padding-top: 112px;
//     justify-content: center;
//   }
// }

.customContainer {
    padding-top: 0;
}

.grid {
  * {
    margin: 0;
  }
  grid-template-columns: 1fr;

  p {
    font-size: 16px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

.icon {
  max-width: 75px;
}
