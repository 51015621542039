@import "../../styles/variables";

.servicesGrid {
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
  gap: 24px;
  padding: 0;

  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 1fr;
}
  li {
    display: grid;
    column-gap: 12px;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    background: $secondaryTrans;
    padding: 18px;
    color: $textAlt;
    border-radius: 8px;
    p {
      grid-column: span 2;
    }
  }
  &__odd {
    grid-template-columns: 1fr;
    @media screen and (min-width: $breakpoint-desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
  }
    li {
      grid-column: unset;
      @media screen and (min-width: $breakpoint-desktop) {
        grid-column: span 2;
    }
    }
    :last-child {
      grid-column: 0;
      @media screen and (min-width: $breakpoint-desktop) {
        grid-column: 2/4;
    }
    }
  }
}
