@import "../../styles/variables";

.button {
  border-radius: 8px;
  background: $primaryColor;
  padding: 12px 36px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  color: $bgColor;
  transition: $transition;

  &:hover {
      background: $primaryColorHover
  }
}