@import "../../../styles/variables";

.sectionBg {
  background-image: url(../../../img/Oxull_strategyBg.png);
}

.twoColumnsTile {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: $breakpoint-desktop) {
    gap: 24px;
    flex-direction: row;
    div:has(img) {
      max-width: 50%;
    }
  }

  &:nth-child(odd) {
    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row-reverse;
    }
  }

  .img {
    max-width: unset;
    img {
      border-radius: 8px;
    }
  }
}
