@import "../../styles/variables";

.callToAction {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  color: $primaryColor;
  font-size: 24px;
  text-align: center;
  font-weight: 800;
  width: 75%;
    margin: 0 auto;

    border: 2px solid $primaryColor;
    font-size: 1.5rem;
    animation: pulse 1.3s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}