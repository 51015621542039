
@import "../../styles/variables";

.imageWrapper {
  display: flex;
  justify-content: center;
  max-width: 400px;
  align-items: center;
  .image {
    width: 100%;
  }

  .grad {
    width: 100%;
    height: 100%;
  }
}
