@import "../../styles/variables";

.olList {
  counter-reset: custom-counter;
  padding-left: 0;
  list-style: none;
  align-content: space-around;
  li {
    counter-increment: custom-counter;
    margin-bottom: 10px;
    padding-top: 40px;
    position: relative;
    font-size: 1.2rem;
    color: #333;

    @media screen and (min-width: $breakpoint-tablet) {
      padding-left: 40px;
      padding-top: 0;
    }

    &::before {
      content: counter(custom-counter);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 1.5rem;
      font-weight: bold;
      color: $primaryColor;

      @media screen and (min-width: $breakpoint-tablet) {
        left: 0;
        top: 0;
        transform: translate(0, 0);
      }
    }
  }
}
